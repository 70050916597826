document.addEventListener("turbolinks:load", () => {
	console.log('hola desde orders.js');

	// para el index
	let btn_open_modal_import = document.getElementById('import-orders-btn');
	if (btn_open_modal_import) {
		// console.log(btn_open_modal_import)
		btn_open_modal_import.addEventListener('click', ev => {
			$('#modal-import-orders').modal('show');
		});

	}

	let order_style_select = document.getElementById('order_style-select');
	if(order_style_select) {
		// quita callbacks por turbolinks
		$(document).off("change","#order_style-select");
		// añade el evento a los campos cuando cambian de valor para recalcular
		$(document).on('change', '#order_style-select', function(event) {
			const models_route = $("#order_style-select").data('route')
			const selected_style = event.target.value
			fetch("/"+models_route+"/by_style.json?style="+selected_style)
				.then(response => response.json())
				.then(response => {
					console.log(response);
					document.getElementById("order_code").value = response.code
					document.getElementById("order_description").value = response.description
					document.getElementById("order_size").value = response.sizes
					document.getElementById("consumption").value = response.fabric_consumption
					$('#order_store').val(response.client).trigger('change');
					let order_cloth_select = $('#order_cloth');
                
                	if (order_cloth_select.find("option[value='" + response.fabric + "']").length) {
                	    order_cloth_select.val(response.fabric).trigger('change');
                	} else {
                	    var newOption = new Option(response.fabric, response.fabric, true, true);
                	    order_cloth_select.append(newOption).trigger('change');
                	}
				})
		});
	}
	
	const amount1 = document.getElementById("amount");
	const consumption1 = document.getElementById('consumption');
	const sale_price = document.getElementById('order_sale_price');
	const total_consumption1 = document.getElementById('total_consumption') 
	const invoice_total = document.getElementById('order_total_invoice') 

	if (consumption1){
		consumption1.addEventListener('change', function() {
			calculateAndUpdate();
		});
	}

	if (sale_price) {
		sale_price.addEventListener('change', function() {
			calculateAndUpdate();
		});
	}

	if (amount1) {
		amount1.addEventListener('change', function() {
			calculateAndUpdate();
		});
	}

	function calculateAndUpdate() {
	  	var get_amount = amount1.value || 0;
	  	var get_consumption = consumption1.value || 0;
	  	var get_sale_price = sale_price.value || 0;
	  	total_consumption1.value = get_amount * get_consumption;
	  	invoice_total.value = get_sale_price * get_amount;
	}

	// initialize existing selects
	const selectElements = $(".select3-batches-oders-fabric")
	if(selectElements.length > 0) {
		const route = selectElements[0].dataset.route
		initializeSelect2Models(".select3-batches-oders-fabric", route)
	}
		
	function initializeSelect2Models(selector, route) {
		$(selector).select2({
			language: {
				"noResults": function(){
				return "Sin resultados";
				}
			},
			ajax: {
				url: (route + ".json"),
				data: function (params) {
				var query = {
					description: params.term,
				}
	
				return query;
				},
				processResults: function (data) {
				// Transforms the top-level key of the response object from 'items' to 'results'
				$.map(data, function (obj) {
					obj.text = obj.description
					obj.id = obj.description
				
					return obj;
				});
	
				return {
					results: data,
				}
				}
			},
			placeholder: 'Selecciona una opción',
		})
	}	
	$('#status-menu li').on('click', function() {
		var dataValue = $(this).data('value');
		var dataId = $(this).data('id');
		if (dataValue == 'delivered'){
			$("#sharedModal .modal-title").html("<h5 class='text-center'>Ingresar fecha de entrega</h5>");
			$("#sharedModal .modal-dialog.modal-xl").removeClass("modal-xl");
			$("#sharedModal .modal-body").html(`
			<div class="card">
			<div class="card-body">
				<div class="form-group row row-sm">
				<label class="col-sm-3 col-form-label">Fecha:</label>
					<div class="col-sm-9">
						<input id="date-delivered" type="date" class="form-control" placeholder="Fecha de entrega" required>
					</div>
				</div>
			</div>
			`);
			if ($("#sharedModal .modal-footer .btn-primary").length === 0) {
			    $("#sharedModal .modal-footer").append("<button type='submit' class='btn btn-primary' id='btnChange'>Guardar</button>");
			}
			$("#sharedModal").modal("show");
			$("#btnChange").on("click", function() {
				var date = $("#date-delivered").val();
				if (date.trim() === "") {
					alert("La fecha es requerida. Por favor, ingrese una fecha.");
				} else {
					var csrfToken = $('meta[name=csrf-token]').attr('content');
					$.ajax({
						url: `/pedidos/${dataId}/change_status?new_status=${dataValue}`,
						method: 'PUT',
						headers: {
							'X-CSRF-Token': csrfToken
						},
						data: { delivery_date: date },
						success: function(response) {
							$("#sharedModal").modal("hide");
						},
						error: function() {
						// Manejo de errores
						}
					});
				}
			});
		}
	});

	$('#switchPacking').change(function() {
		if ($(this).is(':checked')) {
		  	$("#packing_container").removeClass('d-none')
		} else {
			$("#packing_container").addClass('d-none');
			cleanPackingSection();
		}
	});

	// Cantidad embalaje/equivalencia-empaque
	function updateQuantityValues() {
    let quantity = parseFloat($("#amount").val());
	let equivalencePackaging = parseFloat($("#order_packaging_equivalence").val());
    $("#order_packaging_quantity").val(quantity / equivalencePackaging).prop('disabled', true);
    if ($("#switchPacking").is(":checked")) {
      let equivalencePacking = parseFloat($("#order_packing_equivalence").val());
      // Dividir cantidad/cantidad empaque
      //   $("#order_packing_quantity").val(quantity / equivalencePacking);
	  //   let equivalencePackaging = parseFloat($("#order_packaging_equivalence").val());
      let quantityPackaging = parseFloat($("#order_packaging_quantity").val());
      $("#order_packing_quantity").val(quantityPackaging / equivalencePacking).prop('disabled', true);
    }
  }

	function updateUnitText(elementId, unitTextId) {
	  var selectedText = $(elementId).find("option:selected").text();
	  selectedText += /[aeiou]$/i.test(selectedText) ? "s" : "es";
	  $(unitTextId).text(selectedText);
	}

	$("#amount, #order_packaging_equivalence, #order_packing_equivalence").on('change', function() {
	  updateQuantityValues();
	});

	$("#order_packaging_capacity_id").on('change', function() {
	  updateUnitText(this, "#unit_packaging_capacity");
	});

	$("#order_packing_capacity_id").on('change', function() {
	  updateUnitText(this, "#unit_packing_capacity");
	});

	function cleanPackingSection() {
		let field_ids = ["#order_packing_id", "#order_packing_capacity_id", "#order_packing_unit_id","#order_packing_equivalence", "#order_packing_quantity", "#order_packing_form_id", "#order_packing_specifications"];
		field_ids.forEach(function (field, index) {
			$(field).val(null).prop('disabled', false);
			if (index == 0 || index == 5){
				$(field).trigger("change")
			}
		})
	}

	let orderForm = document.getElementById('order-form');
	if (orderForm) {
		// al entrar al form llamar las funciones
		updateMatixStructue("general");

		$('#sizes-list').on('cocoon:after-insert', function (e, insertedItem) {
			updateMatixStructue("insert", "sizes", insertedItem);
		});
		$('#sizes-list').on('cocoon:after-remove', function (e, removedItem) {
			// recalcular al eliminar
			updateMatixStructue("remove", "sizes", removedItem);
		});

		$('#colors-list').on('cocoon:after-insert', function (e, insertedItem) {
			updateMatixStructue("insert", "colors", insertedItem);
		});
		$('#colors-list').on('cocoon:after-remove', function (e, removedItem) {
			// recalcular al eliminar
			updateMatixStructue("remove", "colors", removedItem);
		});

		function addCallbacksToCalculateTotals() {
			// quita callbacks por turbolinks
			$(document).off("change", ".calculate-size-color");
			// añade el evento a los campos cuando cambian de valor para recalcular
			$(document).on('change', '.calculate-size-color', function () {
				calculateXYTotals();
			});
		}

		// operation (insert y remove), name_nested (sizes y colors), y div es el elemento insertado o eliminado
		// es llamada cada vez que se insertan/eliminan tallas/colores
		function updateMatixStructue(operation, name_nested, div) {//para agreagar los tds intermedios de XY en la tabla
			switch (operation) {
				case "insert":
					// Al insertar elementos se le pone el index
					setIndexesToSizesAndColors();
					if (name_nested == "sizes") {
						// cuando se insertan tallas, da vueltas en colores y a cada tr le inserta un td por cada talla 
						$('[data-tipo="color"]').each(function (index, color) {
							if ($(color).is(':visible')) {
								// Obtiene el index del color y el de la talla insertada
								const index_x = $(color).attr("data-index");
								const index_y = $(div).attr("data-index");
								$(color).append(buildMatrixField(index_x, index_y));
							}
						})
					} else if (name_nested == "colors") {
						// cuando se insertan colores se le insertan los td por cada talla
						$('[data-tipo="talla"]').each(function (index, size) {
							if ($(size).is(':visible')) {
								// Obtiene el index de la talla y del color insertado
								const index_x = $(div).attr("data-index");
								const index_y = $(size).attr("data-index");
								$(div).append(buildMatrixField(index_x, index_y));
							}
						})
					}
					break;
				case "remove":
					$("[data-tipo='matriz_eliminar']").hide();
					if (name_nested == "sizes") {
						// cuando se eliminan tallas se elimina el campo por cada color
						$('[data-tipo="color"]').each(function (index, elem) {
							// Busca los td de la matriz a borrar por cada color los que coincidan con el index de la talla eliminada, los oculta
							// y le pone el campo _destroy en true para que se elimine de la BD
							var index_x = $(elem).attr("data-index");
							var index_y = $(div).attr("data-index");
							// console.log(index_x + "-" + index_y)
							$("[data-x='" + index_x + "'][data-y='" + index_y + "'][data-tipo='matrix_field'] [data-tipo='destruir']").val("true");
							// console.log($("[data-x='"+index_x+"'][data-y='"+index_y+"'][data-tipo='matrix_field']").length)
							$("[data-x='" + index_x + "'][data-y='" + index_y + "'][data-tipo='matrix_field']").css('display', 'none').attr('data-x', '').attr('data-y', '').attr({ 'data-x': '', 'data-y': '' });
						});
					} else if (name_nested == "colors") {
						// Busca los td de la matriz a borrar que coincidan con el index del color eliminado, los oculta
						// y le pone el campo _destroy en true para que se elimine de la BD
						var index_x = $(div).attr("data-index");
						$("[data-x='" + index_x + "'][data-tipo='matrix_field'] [data-tipo='destruir']").val("true");
						// console.log($("[data-x='"+index_x+"'][data-tipo='matrix_field']").length)
						$("[data-x='" + index_x + "'][data-tipo='matrix_field']").css('display', 'none').attr('data-x', '').attr('data-y', '').attr({ 'data-x': '', 'data-y': '' });
					}
					// Se restablecen los index y los campos 'x' y 'y' en cada matrix_field
					reestoreIndex();
					$("[data-tipo='matriz_eliminar']").show();
					break;
				case "general":
					// cuando carga por primera vez, sirve cuando es pedido editado se le ponen los index a 
					// cada color y talla
					setIndexesToSizesAndColors();

					// acomoda los matriz fields en orden en la tabla
					$('[data-tipo="color"]:visible').each(function (index, elem) {
						// console.log(elem)
						const ind = $(elem).attr("data-index");
						$('[data-tipo="matrix_field"][data-x="' + ind + '"]').each(function (index, m) {
							$(elem).append(m);
						});
					});

					break;
			}
			// Calcular los totales o recalcular matriz si esta activado el campo proporciones
			calculateXYTotals();
			addCallbacksToCalculateTotals();
		}

		function buildMatrixField(index_x, index_y) {
			const nuevo_id = (new Date().getTime() + '' + index_x + '' + index_y);

			return '<td data-tipo="matrix_field" data-x="' + index_x + '" data-y="' + index_y + '">' +
				'<input type="hidden" name="order[order_matrices_attributes][' + nuevo_id + '][_destroy]" id="order_order_matrices_attributes_' + nuevo_id + '__destroy" value="false" data-tipo="destruir">' +
				'<input type="hidden" name="order[order_matrices_attributes][' + nuevo_id + '][x]" id="order_order_matrices_attributes_' + nuevo_id + '_x" value="' + index_x + '" data-tipo="matriz_x">' +
				'<input type="hidden" name="order[order_matrices_attributes][' + nuevo_id + '][y]" id="order_order_matrices_attributes_' + nuevo_id + '_y" value="' + index_y + '" data-tipo="matriz_y">' +
				'<div class="field">' +
				'<label for="order_order_matrices_attributes_' + nuevo_id + '_pieces" class="active col-form-label">Cantidad</label>' +
				'<input type="number" name="order[order_matrices_attributes][' + nuevo_id + '][pieces]" id="order_order_matrices_attributes_' + nuevo_id + '_pieces" data-tipo="pieces" class="calculate-size-color form-control" value="0">' +
				'</div>' +
				'</td>';
		}

		// Poner los index a los colores tallas, se llama desde general  e insertar elementos
		function setIndexesToSizesAndColors() {
			$('[data-tipo="talla"]:visible').each(function (index, elem) {
				// console.log(elem)
				$(elem).attr("data-index", index);
			});
			$('[data-tipo="color"]:visible').each(function (index, elem) {
				// console.log(elem)
				$(elem).attr("data-index", index);
			});
		}

		// Cambiar los index cuando se elimina una talla o color, se llama al eliminar talla o color
		function reestoreIndex() {
			$('[data-tipo="talla"]:visible').each(function (index, elem) {
				// console.log(elem)
				var anterior = $(elem).attr("data-index");
				$(elem).attr("data-index", index);
				// console.log($("[data-tipo='matrix_field'][data-y='"+anterior+"']")); 
				$("[data-tipo='matrix_field'][data-y='" + anterior + "'] [data-tipo='matriz_y']").val(index);
				$("[data-tipo='matrix_field'][data-y='" + anterior + "']").attr('data-y', index);
			});
			$('[data-tipo="color"]:visible').each(function (index, elem) {
				// console.log(elem)
				var anterior = $(elem).attr("data-index");
				$(elem).attr("data-index", index);
				// console.log($("[data-tipo='matrix_field'][data-x='"+anterior+"']")); 
				$("[data-tipo='matrix_field'][data-x='" + anterior + "'] [data-tipo='matriz_x']").val(index);
				$("[data-tipo='matrix_field'][data-x='" + anterior + "']").attr('data-x', index);
			});
		}

		// es llamada cada vez que se elimina o inserta una talla/color, al cargar la pagina 
		// desde calcularTallaColor() y cuando cambia alguna cantidad de la matriz
		function calculateXYTotals() { // Totales de tallas y totales de colores
			var totalxy = 0;
			$("#totales-tallas").remove();
			$(".totales").remove();
			$("#sizes-list").append("<td class='totales h6 center-align'>Totales</td>");
			$("[data-tipo='color']:visible").each(function (index_color, color) {
				var total_color = 0;
				// si esta visible en el dom
				$(color).find("[data-tipo='matrix_field']").each(function (index_td, td) {
					// console.log($(td))
					total_color += parseFloat($(td).find("[data-tipo='pieces']").val() || 0);
				})
				$(color).append("<td class='totales center-align'>Piezas: " + total_color + "</td>");
				totalxy += total_color;
				// console.log(total_color);
			});

			$("#colors-list").append("<tr id='totales-tallas'><td class='right-align h6 totales'>Totales</td></tr>");
			$("[data-tipo='talla']:visible").each(function (index_talla, talla) {
				var total_talla = 0;
				// si esta visible en el dom
				$("[data-tipo='color']:visible").each(function (index_color, color) {
					// si esta visible en el dom
					// encontrar todos los totales de la talla
					var id = "[data-tipo='matrix_field'][data-x='" + $(color).attr('data-index') + "'][data-y='" + $(talla).attr('data-index') + "']";
					total_talla += parseFloat($(id).find("[data-tipo='pieces']").val() || 0);
				});
				$("#totales-tallas").append("<td class='totales center-align'>Piezas: " + total_talla + "</td>");
				// console.log(total_talla)
			});
			$("#totales-tallas").append("<td class='totales center-align' style='text-decoration:underline'>Piezas: " + totalxy + "</td>");
		}
	}
	
});